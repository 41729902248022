import React from "react";

const Dashboard = () => {
    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="text-center text-white text-2xl">Admin Dashboard</div>
            <div className="text-center text-grey text-lg">More coming soon ...</div>
        </div>
    );
};

export default Dashboard;
