import React, { useEffect, useState } from "react"
import Header from "./header"
import LandingFooter from "./landingFooter"
import { SetLoadingStatus } from "../actions/appActions"
import { doGetExpiryTime, resendConfirmEmail } from "../api/api"
import { useDispatch } from "react-redux"
import { showAlert } from "../actions/alertActions"
import { useParams } from "react-router-dom"

const ConfirmEmail = () => {

    const dispatch = useDispatch()
    const { email, password } = useParams()
    const [expiryTime, set_expiryTime] = useState(0)

    const getExpiryTime = async () => {
        console.log(email, password)
        SetLoadingStatus(true)
        const response = await doGetExpiryTime({ email, password })
        console.log(response)
        if (response.status === 'SUCCESS') {
            set_expiryTime(response.expiryTime)
        } else {
            dispatch(showAlert(response.error))
        }
        SetLoadingStatus(false)
    }

    const resend = async () => {
        SetLoadingStatus(true)
        const response = await resendConfirmEmail({ email, password })
        if (response.status === 'SUCCESS') {
            dispatch(showAlert('Verification email is sent again.'))
            set_expiryTime(response.expiryTime)
        } else {
            dispatch(showAlert(response.error))
        }
        SetLoadingStatus(false)
    }

    useEffect(() => {
        getExpiryTime()
    }, [])

    return (
        <>
            <Header />
            <div className="w-full h-[400px] flex flex-col items-center justify-center">
                <div className="text-center text-white text-2xl">We are waiting for you to confirm your email address.</div>
                <div className="text-center text-white text-2xl">Confirmation Email Sent to {email}</div>
                {
                    expiryTime <= new Date().getTime() ?
                        <div className="text-center text-grey text-lg">The confirmation link is expired</div> :
                        <div className="text-center text-grey text-lg">The confirmation link expires in {`${new Date(expiryTime).getFullYear()}/${("0" + (new Date(expiryTime).getMonth() + 1)).slice(-2)}/${("0" + new Date(expiryTime).getDate()).slice(-2)} ${("0" + new Date(expiryTime).getHours()).slice(-2)}:${("0" + new Date(expiryTime).getMinutes()).slice(-2)}`}</div>
                }
                <button
                    className="px-3 py-1 border border-green rounded-md text-white text-lg mt-6 bg-green hover:text-green hover:bg-transparent"
                    onClick={resend}
                >
                    Resend
                </button>
            </div>
            <LandingFooter />
        </>
    )
}

export default ConfirmEmail